@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Calibri';
  src: url('./fonts/Calibri Bold.TTF') format('truetype');
  font-weight: 700;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw; 
  overflow-x: hidden;

}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title-font{
  font-family:'Calibri', sans-serif ;
  font-weight: 700;
}

.jobfirst-text{
  font-weight: 900;
  color: black;
}