@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda+SC:ital,opsz,wght@0,6..96,400..900;1,6..96,400..900&family=DM+Serif+Text:ital@0;1&family=Edu+VIC+WA+NT+Beginner:wght@400..700&family=Lobster&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

/* From Uiverse.io by elijahgummer */ 
.styled-button {
  position: relative;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(to bottom, #171717, #242424);
  border-radius: 9999px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 1), 0 10px 20px rgba(0, 0, 0, 0.4);
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #292929;
}

.styled-button::before {
  content: "";
  position: absolute;
  top: -2px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  background: linear-gradient(to bottom, #292929, #000000);
  z-index: -1;
  border-radius: 9999px;
  transition: all 0.2s ease;
  opacity: 1;
}

.styled-button:active {
  transform: translateY(2px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 1), 0 5px 10px rgba(0, 0, 0, 0.4);
}

.styled-button .inner-button {
  position: relative; /* Ensure relative positioning for pseudo-element */
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #171717, #242424);
  width: 40px;
  height: 40px;
  margin-left: 10px;
  border-radius: 50%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 1);
  border: 1px solid #252525;
  transition: all 0.2s ease;
}

.styled-button .inner-button::before {
  content: "";
  position: absolute;
  top: -2px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  background: linear-gradient(to bottom, #292929, #000000);
  z-index: -1;
  border-radius: 9999px;
  transition: all 0.2s ease;
  opacity: 1;
}
.styled-button .inner-button .icon {
  filter: drop-shadow(0 10px 20px rgba(26, 25, 25, 0.9))
    drop-shadow(0 0 4px rgba(0, 0, 0, 1));
  transition: all 0.4s ease-in-out;
}
.styled-button .inner-button .icon:hover {
  filter: drop-shadow(0 10px 20px rgba(50, 50, 50, 1))
    drop-shadow(0 0 20px rgba(2, 2, 2, 1));
  transform: rotate(-35deg);
}

.comfortaa> {
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
}
.source {
  font-family: "Source Sans 3", sans-serif;
  font-weight: 100;
  font-style: normal;
}
.assosiated{
  font-family: 'Poppins', sans-serif;
}
.roboto {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.dm-serif-text-regular {
    font-family: "DM Serif Text", serif;
    font-weight: 400;
    font-style: normal;
}

.grey-qo-regular {
  font-family: "Bodoni Moda SC", serif;
  font-optical-sizing: auto;
  font-weight:bold;
  font-style: normal;
  
}

  
.edu-beginner {
    font-family: "Edu VIC WA NT Beginner", cursive;
    font-weight: 400;
}

.navsbars {
    font-family: edu-beginner;
}
  
.heading {
    font-size: clamp(30px, 5vw, 60px);
    font-family: dm-serif-text-regular;
    font-weight: bold;
    color: #fff;
    text-align: center;
}

.bank_partner {
    font-size: clamp(20px, 3vw, 35px);
    font-family: dm-serif-text-regular;
    color: gray;
    text-align: center;
}
.contactsng {
  font-size: clamp(18px, 2vw, 25px);
  font-family: dm-serif-text-regular;
  color: gray;
  text-align: center;
}

section .animate {
    opacity: 0;
    transition: transform 1s, filter 1s, opacity 1s;
    filter: blur(8px);
    overflow-x: hidden;
}

section.show_animate .animate {
    filter: blur(0);
    opacity: 1;
}

.sec1 .animate {
    transform: translateX(-50%);
    overflow-x: hidden;
}



section .animate1 {
  opacity: 0;
  transition: transform 0.2s, filter 0.2s, opacity 0.2s;
  filter: blur(8px);
  overflow-x: hidden;
}

section.show_animate1 .animate1 {
  filter: blur(0);
  opacity: 1;
}

.sec1 .animate1 {
  transform: translateX(-50%);
  overflow-x: hidden;
}




.sec1.show_animate .animate {
    transform: translateX(0);
}

.sec2 .animate {
    transform: scale(.7);
}

.sec2.show_animate .animate {
    transform: scale(1);
}

.sec3 .animate {
    transform: translateX(100%);
    transition-delay: calc(.2s * var(--i));
}

.sec3.show_animate .animate {
    transform: translateX(0);
}



.sec4 .animate {
    transform: translateX(-70%);
}

.sec4.show_animate .animate {
    transform: translateX(0);
}

.sec-6 {
    transform: translateX(100%);
}

.sec-6.show_animate .animate {
    transform: translateX(0);
}




.together{
    display: flex;
}

  .navbar-menu.open {
  
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    background: #5f0b66;
    color: white;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
  }


@media (min-width: 900px) {
    .navbar {
        width: 50%;
        margin-left: auto; 
        margin-right: auto;
        height: '10px';
      }
        .navbar-menu {
          display: none;
        }
    .contact-section .heading,
    .contact-section .bank_partner {
      opacity: 0;
      transition: opacity 0.1s, transform 0.1s;
    }
  
    .contact-section.show_animate .heading,
    .contact-section.show_animate .bank_partner {
      opacity: 1;
      transform: translateY(0);
    }
  
    .contact-section .heading {
      transform: translateY(-50px);
    }
  
    .contact-section .bank_partner {
      transform: translateY(50px);
    }
  
   
  }


  @media (max-width: 900px) {
    .animate {
      animation: cursorMove 0.5s ease-in-out; /* Double the speed */
    }
    .careershead{
      font-weight: bold;
      font-size: '100px' !important;
    }
    .aboutus{
      margin-bottom: 40px !important;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important;
      font-size: 11vw !important;
      font-style: italic;

    }
    .library{
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important;
      font-size: 11vw !important;
      font-style: italic;
    }
    .jobsfirst{
      font-size: 60px !important;
    }
    .bankassoci{
      /* font-size: 20px !important; */
      /* margin-top: -20px !important; */
      margin-left: 10px;

    }
    .wayanad{
      font-size: 20px !important;
      margin-left: 0px !important;
      margin-top: 4px !important;
      font-weight: bold !important;
    }
    .whats{
      width: 9px !important;
      margin-right: 20px;
    }
    .whats-ic{
      width: 23px !important;
      margin-bottom: 10px;
      margin-top: -10px;
    }
    .main_d{
      margin-top: 40px;
      display: flex !important;
    }
    .rows{
      margin-left: -15px !important;
    }
    .column{
      margin-left: -51px !important;
      margin-top: -16px;
    }
    .facebk{
      margin-top: -50px;
    }
    .job{
      margin-bottom: 15px !important;
    }
    .first{
      margin-left: 10px;
    }
    .faceb{
      margin-left: -10px !important;
    }
    .facesvg{
      margin-right: 10px;
    }
  }

  